<template lang="html">
  <div class="">
    <div class="main-container">
      <div class="h2">
        <button class="btn-back" type="button" name="button" @click="$router.go(-1)">
          <span class="icon-w-back"></span>
        </button>
        <span>绑定手机号</span>
      </div>
      <div class="" v-if="userInfo">
        <div class="">
          <input class="form-control-p" type="text"
          disabled
          v-model="userInfo.tel">
        </div>
      </div>
      <div class="text-help m-t">
        暂不支持修改绑定手机号
      </div>
      <!-- <div class="btn-group m-t">
        <button type="button" name="button" class="btn btn-success btn-md">确定</button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
  },
};
</script>

<style lang="css" scoped>
.info-avatar {
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.info-avatar > img {
  width: 100%;
}
</style>
